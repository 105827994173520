import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-44db82d4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "contract-order"
};
const _hoisted_2 = {
  class: "contract-order__title"
};
const _hoisted_3 = {
  class: "contract-order__row"
};
const _hoisted_4 = {
  class: "text-right"
};
const _hoisted_5 = {
  class: "contract-order__text"
};
const _hoisted_6 = {
  class: "contract-order__text"
};
const _hoisted_7 = {
  class: "contract-order__text"
};
const _hoisted_8 = {
  class: "contract-order__text"
};
const _hoisted_9 = {
  class: "contract-order__text"
};
const _hoisted_10 = {
  class: "contract-order__title"
};
const _hoisted_11 = {
  class: "contract-order__row"
};
const _hoisted_12 = {
  class: "text-right"
};
const _hoisted_13 = {
  class: "contract-order__text"
};
const _hoisted_14 = {
  class: "contract-order__text"
};
const _hoisted_15 = {
  class: "contract-order__text"
};
const _hoisted_16 = {
  class: "contract-order__text"
};
const _hoisted_17 = {
  class: "contract-order__text"
};
const _hoisted_18 = {
  class: "cancel-popup"
};
const _hoisted_19 = {
  class: "cancel-popup__title"
};
const _hoisted_20 = {
  class: "cancel-popup__foot"
};
import NP from 'number-precision';
import { router } from '@/router';
import { ref } from 'vue';
import { liquidityOrder, cancelLpOrder } from '@/http';
import { showToast } from 'vant';
export default {
  __name: 'liquidityOrder',
  setup(__props) {
    const list = ref([[], []]);
    const page = ref([1, 1]);
    const active = ref(0);
    const loading = ref([false, false]);
    const finished = ref([false, false]);
    const show = ref(false);
    const selectItem = ref(null);
    const _cancelLpOrder = item => {
      selectItem.value = item;
      show.value = true;
    };
    const _cancel = () => {
      cancelLpOrder({
        lp_order_id: selectItem.value.id
      }).then(({
        msg
      }) => {
        show.value = false;
        showToast(msg);
        _liquidityOrder();
      });
    };
    const _liquidityOrder = () => {
      liquidityOrder({
        type: active.value,
        page: page.value[active.value],
        perPage: 2
      }).then(({
        data
      }) => {
        loading.value[active.value] = false;
        if (data.current_page === 1) {
          list.value[active.value] = data.data;
        } else {
          list.value[active.value] = list.value[active.value].concat(data.data);
        }
        finished.value[active.value] = data.current_page >= data.last_page;
        if (!finished.value[active.value]) {
          page.value[active.value]++;
        }
      });
    };
    const back = () => {
      router.go(-1);
    };
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      const _component_van_button = _resolveComponent("van-button");
      const _component_van_list = _resolveComponent("van-list");
      const _component_van_tab = _resolveComponent("van-tab");
      const _component_van_tabs = _resolveComponent("van-tabs");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
        "left-arrow": "",
        title: _ctx.$t('liquidity_pool_staking_order'),
        placeholder: "",
        fixed: "",
        onClickLeft: back
      }, null, 8, ["title"]), _createElementVNode("div", _hoisted_1, [_createVNode(_component_van_tabs, {
        active: active.value,
        "onUpdate:active": _cache[2] || (_cache[2] = $event => active.value = $event),
        color: "#FC72FF",
        sticky: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_van_tab, {
          title: _ctx.$t('in_progress')
        }, {
          default: _withCtx(() => [_createVNode(_component_van_list, {
            loading: loading.value[0],
            "onUpdate:loading": _cache[0] || (_cache[0] = $event => loading.value[0] = $event),
            finished: finished.value[0],
            "finished-text": _ctx.$t('no_more'),
            onLoad: _liquidityOrder
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value[0], item => {
              return _openBlock(), _createElementBlock("div", {
                class: "contract-order__item",
                key: item.id
              }, [_createElementVNode("div", _hoisted_2, _toDisplayString(item.days) + _toDisplayString(_ctx.$t('day')), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.amount)), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('amount')) + "(USDT)", 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.one_rate)), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('daily_profit')) + "(USDT)", 1)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.tot_rate) || '-'), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('total_return_rate')) + "(USDT)", 1)])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('due_date')), 1), _createElementVNode("span", null, _toDisplayString(item.days), 1)]), _createElementVNode("div", _hoisted_6, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('return_rate')), 1), _createElementVNode("span", null, _toDisplayString(item.rate) + "%", 1)]), _createElementVNode("div", _hoisted_7, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('order_id')), 1), _createElementVNode("span", null, _toDisplayString(item.id), 1)]), _createElementVNode("div", _hoisted_8, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('creation_time')), 1), _createElementVNode("span", null, _toDisplayString(item.created_at), 1)]), _createElementVNode("div", _hoisted_9, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('expiration_time')), 1), _createElementVNode("span", null, _toDisplayString(item.end_time), 1)]), item.state === 0 ? (_openBlock(), _createBlock(_component_van_button, {
                key: 0,
                plain: "",
                block: "",
                round: "",
                color: "#FC72FF",
                style: {
                  "margin-top": "15px"
                },
                onClick: $event => _cancelLpOrder(item)
              }, {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('cancel_order')), 1)]),
                _: 2
              }, 1032, ["onClick"])) : _createCommentVNode("", true)]);
            }), 128))]),
            _: 1
          }, 8, ["loading", "finished", "finished-text"])]),
          _: 1
        }, 8, ["title"]), _createVNode(_component_van_tab, {
          title: _ctx.$t('has_ended')
        }, {
          default: _withCtx(() => [_createVNode(_component_van_list, {
            loading: loading.value[1],
            "onUpdate:loading": _cache[1] || (_cache[1] = $event => loading.value[1] = $event),
            finished: finished.value[1],
            "finished-text": _ctx.$t('no_more'),
            onLoad: _liquidityOrder
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(list.value[1], item => {
              return _openBlock(), _createElementBlock("div", {
                class: "contract-order__item",
                key: item.id
              }, [_createElementVNode("div", _hoisted_10, _toDisplayString(item.days) + _toDisplayString(_ctx.$t('day')), 1), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.amount)), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('amount')) + "(USDT)", 1)]), _createElementVNode("div", null, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.one_rate)), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('daily_profit')) + "(USDT)", 1)]), _createElementVNode("div", _hoisted_12, [_createElementVNode("p", null, _toDisplayString(parseFloat(item.tot_rate) || '-'), 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('total_return_rate')) + "(USDT)", 1)])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('due_date')), 1), _createElementVNode("span", null, _toDisplayString(item.days), 1)]), _createElementVNode("div", _hoisted_14, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('return_rate')), 1), _createElementVNode("span", null, _toDisplayString(item.rate) + "%", 1)]), _createElementVNode("div", _hoisted_15, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('order_id')), 1), _createElementVNode("span", null, _toDisplayString(item.id), 1)]), _createElementVNode("div", _hoisted_16, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('creation_time')), 1), _createElementVNode("span", null, _toDisplayString(item.created_at), 1)]), _createElementVNode("div", _hoisted_17, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('expiration_time')), 1), _createElementVNode("span", null, _toDisplayString(item.end_time), 1)])]);
            }), 128))]),
            _: 1
          }, 8, ["loading", "finished", "finished-text"])]),
          _: 1
        }, 8, ["title"])]),
        _: 1
      }, 8, ["active"])]), _createVNode(_component_van_popup, {
        show: show.value,
        "onUpdate:show": _cache[4] || (_cache[4] = $event => show.value = $event),
        round: "",
        closeable: ""
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_18, [_createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t('confirm_cancellation')) + "?", 1), _createElementVNode("p", null, _toDisplayString(_ctx.$t('penalty_rate')) + _toDisplayString(selectItem.value.dedit) + "%，" + _toDisplayString(_ctx.$t('expected_arrival_amount')) + "：" + _toDisplayString(_unref(NP).minus(selectItem.value.amount, _unref(NP).times(selectItem.value.amount, selectItem.value.dedit / 100))) + "USDT", 1), _createElementVNode("div", _hoisted_20, [_createVNode(_component_van_button, {
          round: "",
          onClick: _cache[3] || (_cache[3] = $event => show.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)]),
          _: 1
        }), _createVNode(_component_van_button, {
          round: "",
          color: "#FC72FF",
          onClick: _cancel
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('confirm')), 1)]),
          _: 1
        })])])]),
        _: 1
      }, 8, ["show"])], 64);
    };
  }
};